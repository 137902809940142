import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import img_logo from "../assets/images/logo.png";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar } from "@mui/material";
// import HomeIcon from "@mui/icons-material/Home";
import Cookies from "js-cookie";
import { handleLogOut } from "../slices/employSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate, Link } from "react-router-dom";
import LanguageMenu from "./LanguageMenu";
import { useLocalization } from "../lang";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "../utils";
import { useFetch } from "../hooks";
import WorkIcon from "@mui/icons-material/Work";
import NotificationsIcon from "@mui/icons-material/Notifications";
import dayjs from "dayjs";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(["width", "margin"], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
  // marginRight: -drawerWidth,
  // ...(open && {
  //   transition: theme.transitions.create("margin", {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   marginRight: 0,
  // }),
}));

const Drawer = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    minWidth: "4em",
    height: "100vh",
    overflow: "hidden",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    paddingTop: "5em",
    position: "fixed",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

const Slider = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { translator } = useLocalization();
  const user = useSelector((state) => state.user);

  const { refetch } = useFetch("/auth/logout", {
    onSuccess: () => {
      Cookies.remove("token");
      dispatch(handleLogOut());
      navigate("/login");
    },
    enabled: false,
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ background: "#ecf0f1" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{
                // position: "absolute",
                // left: 26,
                // marginRight: 5,
                borderRadius: "50%",
                background: "#a62639",
                height: "2em",
                width: "2em",
                "&:hover": {
                  background: "#a62639",
                },
              }}
              hover
            >
              {open ? (
                theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )
              ) : (
                <MenuIcon />
              )}
            </IconButton>
            <Box
              sx={{
                display: "flex",
                marginInlineStart: "1em",
                // position: "absolute",
                // left: 60,
              }}
            >
              <img
                style={{ width: 70, marginLeft: 5, marginRight: 5 }}
                src={img_logo}
                alt="logo"
              />
            </Box>
          </Box>
          {/* Language menu */}
          <Box
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{
              marginInlineEnd: "3em",
              borderRadius: "50%",
              width: "fit-content",
            }}
            hover
          >
            <LanguageMenu />
            {/* <PersonIcon /> */}
          </Box>
          {/* <AccountMenu /> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          zIndex: 100,
          backgroundColor: "white",
        }}
      >
        <Divider />
        <List sx={{ height: "100%" }}>
          <ListItem disablePadding sx={{ display: "block", border: "none" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    // padding: 1,
                    borderRadius: "50%",
                    background: "#b2bec3",
                  }}
                >
                  <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    {user.profile_pic ? (
                      <img
                        src={user.profile_pic}
                        alt={user.name}
                        style={{
                          width: 50,
                          height: 50,
                          objectFit: "fill",
                        }}
                      />
                    ) : (
                      user.name && user.name[0]
                    )}
                  </Avatar>
                </Box>
              </ListItemIcon>
              <Box sx={{ width: open ? "100%" : "0px", overflow: "hidden" }}>
                <ListItemText primary={capitalize(user?.name || "")} />
                <Typography variant="caption" display="block" gutterBottom>
                  {capitalize(user?.type || "")}
                </Typography>
              </Box>
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "start" : "center",
                  px: 2.5,
                  "&:hover": {
                    background: "#ffe0e5",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginInlineEnd: open ? 3 : "auto",
                    justifyContent: "start",
                  }}
                >
                  <HomeIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText
                  primary={translator("home")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem> */}
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/profile">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "start" : "center",
                  px: 2.5,
                  "&:hover": {
                    background: "#ffe0e5",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginInlineEnd: open ? 3 : "auto",
                    justifyContent: "start",
                  }}
                >
                  <PersonIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText
                  primary={translator("profile")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/jobs">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "start" : "center",
                  px: 2.5,
                  "&:hover": {
                    background: "#ffe0e5",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginInlineEnd: open ? 3 : "auto",
                    justifyContent: "start",
                  }}
                >
                  <WorkIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText
                  primary={translator("jobs")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/notifications">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "start" : "center",
                  px: 2.5,
                  "&:hover": {
                    background: "#ffe0e5",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginInlineEnd: open ? 3 : "auto",
                    justifyContent: "start",
                  }}
                >
                  <NotificationsIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText
                  primary={translator("notifications")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <Box
            sx={{
              display: "block",
              position: "absolute",
              bottom: "2em",
              left: 0,
              right: 0,
            }}
          >
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => refetch()}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "start" : "center",
                  px: 2.5,
                  "&:hover": {
                    background: "#ffe0e5",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginInlineEnd: open ? 3 : "auto",
                    justifyContent: "start",
                  }}
                >
                  <LogoutIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText
                  primary={translator("logout")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Box>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              background: "#ffffff",
              border: "none",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              bottom: 0,
            }}
          >
            <Box
              sx={{
                minHeight: 28,
                justifyContent: open ? "initial" : "center",
                px: 0,
                display: "flex",
              }}
            >
              <Box
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                  border: "none",
                  m: "auto",
                  position: "absolute",
                  bottom: 0,
                }}
              >
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{
                    display: open ? "none" : "flex",
                    textAlign: "center",
                    m: "auto",
                    width: "fit-content",
                    overflow: "hidden",
                  }}
                >
                  @{dayjs().year()}
                </Typography>
              </Box>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{
                  display: open ? "flex" : "none",
                  textAlign: "center",
                  m: "auto",
                  width: "fit-content",
                  overflow: "hidden",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                Copyrights @{dayjs().year()}
              </Typography>
            </Box>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ width: "100%", paddingInlineStart: "4em" }}>
        {/* <DrawerHeader />
        <Layout /> */}
        {children}
      </Box>
    </Box>
  );
};

export default Slider;
